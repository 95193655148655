<template>
  <section>
    <p>Статистика</p>

    <select v-model="choosedYear">
      <option>Выберите год</option>
      <option v-for="year in years" :key="year" :value="year">
        {{ year }}
      </option>
    </select>

    <canvas id="myChart" width="400" height="400"></canvas>

    <div class="list_container">
      <ul class="comes" :class="{ 'is-active': listIsShown[0] }">
        <span @click="toggleList(0)">Приходы</span>
        <li>
          <p class="name">Наименование</p>
          <p class="price">Цена</p>
          <p class="date">Дата (Г-М-Д)</p>
        </li>
        <li v-for="(item, index) in comes.slice(0, 5)" :key="item + index">
          <p class="name">{{ item.itemName }}</p>
          <p class="price">{{ item.resultSum }}</p>
          <p class="date">{{ item.date }}</p>
        </li>
      </ul>
      <ul class="expenses" :class="{ 'is-active': listIsShown[1] }">
        <span @click="toggleList(1)">Расходы</span>
        <li>
          <p class="name">Наименование</p>
          <p class="price">Цена</p>
          <p class="date">Дата (Г-М-Д)</p>
        </li>
        <li v-for="(item, index) in expenses.slice(0, 15)" :key="item + index">
          <p class="name">{{ item.itemName }}</p>
          <p class="price">{{ item.resultSum }}</p>
          <p class="date">{{ item.date }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Chart from "chart.js";

async function sendRequest(fileName, data) {
  const response = await fetch(`/php/${fileName}.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(data),
  });

  let answerData = await response.json();

  if (answerData.errors) {
    alert("Произошла ошибка, см. консоль");
    console.log("Ошибка: " + answerData.message);
    return false;
  }
  return answerData;
}

export default {
  data() {
    return {
      comes: [],
      expenses: [],
      xAxis: [],
      yAxis: [],
      listIsShown: [false, false],
      years: [],
      choosedYear: 'Выберите год',
      chart: null,
    };
  },
  mounted() {
    this.getYears();
  },
  computed: {
    componentIsActive() {
      return this.$store.getters["getActiveComponent"] == "Statistics";
    },
  },
  watch: {
    componentIsActive(newValue) {
      if (newValue && this.choosedYear) {
        this.getData(this.choosedYear);
      }
    },
    choosedYear(newValue) {
      this.getData(newValue);
    }
  },
  methods: {
    async getYears() {
      const answer = await sendRequest("/additional/getYearsForStatistic");

      this.years = answer;
    },
    async getData(year) {
      const answer = await sendRequest("/additional/getComesAndExpenses", {
        year,
      });

      this.comes = answer.comes;
      this.expenses = answer.expenses;

      let dataComes = [];
      let dataExpenses = [];
      let dataDifferce = [];

      answer.comes.forEach((item) => {
        let date = Date.parse(item.date);
        date = new Date(date);
        let month = date.getMonth();
        if (dataComes[month]) {
          dataComes[month] += +item.resultSum;
        } else {
          dataComes[month] = +item.resultSum;
        }
      });


      answer.expenses.forEach((item) => {
        let date = Date.parse(item.date);
        date = new Date(date);
        let month = date.getMonth();
        if (dataExpenses[month]) {
          dataExpenses[month] += +item.resultSum;
        } else {
          dataExpenses[month] = +item.resultSum;
        }        
      });

      dataExpenses.forEach((item, index) => {
        dataDifferce[index] = dataComes[index] - item;
      });

      if(this.chart) {
        this.chart.data.datasets[0].data = dataComes;
        this.chart.data.datasets[1].data = dataExpenses;
        this.chart.data.datasets[2].data = dataDifferce;
        this.chart.update();
        return;
      }



      var ctx = document.getElementById("myChart").getContext("2d");
      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: [
            "Янв",
            "Фев",
            "Мар",
            "Апр",
            "Май",
            "Июн",
            "Июл",
            "Авг",
            "Сен",
            "Окт",
            "Ноя",
            "Дек",
          ],
          datasets: [
            {
              data: dataComes,
              backgroundColor: "rgb(102 181 96)",
              borderColor: "rgb(102 181 96)",
              label: "Приходы",
              fill: false,
            },
            {
              data: dataExpenses,
              backgroundColor: "rgb(169 84 84)",
              borderColor: "rgb(169 84 84)",
              label: "Расходы",
              fill: false,
            },
            {
              data: dataDifferce,
              backgroundColor: "rgb(84 157 169)",
              borderColor: "rgb(84 157 169)",
              label: "Прибыль",
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Месяц",
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Сумма (руб.)",
                },
              },
            ],
          },
        },
      });
    },
    toggleList(id) {
      this.listIsShown[id] = !this.listIsShown[id];
    },
  },
};
</script>

<style lang = "scss">
section > p {
  text-align: center;
}
.list_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ul {
    max-width: 100%;
    width: 100%;
    > span {
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid #4183a4;
      padding-bottom: 8px;

      &:before {
        display: block;
        content: "";
        width: 12px;
        min-width: 12px;
        height: 12px;
        background-image: url(~@/assets/img/icons/right-arr.svg);
        background-size: contain;
        margin-right: 8px;
      }
    }
    li {
      display: none;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      border-radius: 8px;
      margin: 8px 0;

      &:first-of-type {
        margin-top: 20px;
        background-color: unset;
        border-bottom: 1px solid rgba(255, 255, 255, 0.322);
        border-radius: 0;
      }
    }

    &.is-active {
      li {
        display: flex;
      }

      > span {
        &:before {
          transform: rotate(90deg);
        }
      }
    }
  }
}

ul.comes {
  li {
    background-color: #66b56031;
  }
}

ul.expenses {
  li {
    background-color: #e153535d;
  }
}

.name,
.price,
.date {
  max-width: 32%;
  width: 100%;
}

@media screen and (max-width: 640px) {
  ul {
    li {
      p {
        font-size: 12px;
      }
    }
  }
}
</style>